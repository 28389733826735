import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonLabel, IonText } from '@ionic/react';
import React from 'react';
import dayjs from 'dayjs';
import { TownStore } from '../store/TownStore';
const Announcements = () => {
  const announcements = TownStore.useState(s => s.announcements);

  if(announcements && announcements.length > 0) {
    return (
      <IonCard mode="ios">
        <IonCardHeader>
          <IonCardSubtitle>Latest</IonCardSubtitle>
          <IonCardTitle>Announcements</IonCardTitle>
        </IonCardHeader>
          <IonCardContent className="ion-padding">
            {announcements.length > 0 ? announcements.map((ann) => (
              ann.link !== "https://lincoln.firepermit.online") ?
              <IonItem 
                href={ ann.link } 
                target="blank" 
                key={ann.id} 
                className="announcementItem ion-padding-vertical" 
                lines="full">
                <IonLabel className="annoucement ion-text-wrap ion-text-uppercase ion-no-margin" position="stacked">{ann.title}</IonLabel>
                <IonText><strong>{dayjs(ann.dateCreated).format('MMMM D, YYYY')}</strong> – {ann.details}</IonText>
              </IonItem>
              :
              <IonItem 
                key={ann.id} 
                className="announcementItem ion-padding-vertical" 
                lines="full">
                <IonLabel className="annoucement ion-text-wrap ion-text-uppercase ion-no-margin" position="stacked">{ann.title}</IonLabel>
                <IonText><strong>{dayjs(ann.dateCreated).format('MMMM D, YYYY')}</strong> – {ann.details}</IonText>
              </IonItem>
              ) : null
            }
          </IonCardContent>
      </IonCard>
    );
  } else {
    return (
      <IonCard mode="ios">
        <IonCardHeader>
          <IonCardSubtitle>Latest</IonCardSubtitle>
          <IonCardTitle>Announcements</IonCardTitle>
        </IonCardHeader>
          <IonCardContent className="ion-padding">
            <p>No announcements at this time.</p>
          </IonCardContent>
      </IonCard>
    )
  }
};

export default Announcements;
  