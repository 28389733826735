import {
    IonContent,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
} from '@ionic/react';

import React from 'react';
import { useLocation } from 'react-router-dom';
import {
    addCircleOutline,
    addCircleSharp,
    flameOutline,
    flameSharp,
    helpCircleOutline,
    helpCircleSharp,
    informationCircleOutline,
    informationCircleSharp,
    logOutOutline,
    logOutSharp,
    personOutline,
    personSharp,
} from 'ionicons/icons';
import './Page.css';
import fpoLogo from '../assets/images/fpoLogo.png';
import { auth } from '../firebase';
import { TownStore } from '../store/TownStore';

const logout = () => {
    auth.signOut();
    window.location.reload();
    console.log('log out');
};

const appPages = [
    {
        title: 'Apply',
        url: '/account',
        iosIcon: addCircleOutline,
        mdIcon: addCircleSharp,
    },
    {
        title: 'My Permits',
        url: '/permits',
        iosIcon: flameOutline,
        mdIcon: flameSharp,
    },
    {
        title: 'My Profile',
        url: '/profile',
        iosIcon: personOutline,
        mdIcon: personSharp,
    },
];

const Menu = () => {
    const location = useLocation();
    const townDoc = TownStore.useState((s) => s.townDoc);

    return (
        <IonMenu contentId='main'>
            <IonContent>
                <IonList id='inbox-list'>
                    <IonListHeader>
                        <img src={fpoLogo} alt='FirePermit.Online' />
                    </IonListHeader>
                    <IonNote>{auth.currentUser.email}</IonNote>
                    {appPages.map((appPage, index) => {
                        return (
                            <IonMenuToggle key={index} autoHide={false}>
                                <IonItem
                                    className={location.pathname === appPage.url ? 'selected' : ''}
                                    routerLink={appPage.url}
                                    routerDirection='none'
                                    lines='none'
                                    detail={false}
                                >
                                    <IonIcon
                                        slot='start'
                                        ios={appPage.iosIcon}
                                        md={appPage.mdIcon}
                                    />
                                    <IonLabel>{appPage.title}</IonLabel>
                                </IonItem>
                            </IonMenuToggle>
                        );
                    })}
                    <IonItemDivider />
                    <IonItem
                        detail='false'
                        lines='none'
                        button
                        href='mailto:info@firepermit.online?subject=Support%20Request%20-%20Lincoln%20Burn%20Permits&body=Please%20provide%20details%20of%20the%20issue%20you%20are%20experiencing%3A'
                    >
                        <IonIcon slot='start' ios={helpCircleOutline} md={helpCircleSharp} />
                        <IonLabel>Help</IonLabel>
                    </IonItem>
                    <IonItem
                        detail='false'
                        lines='none'
                        button
                        href={townDoc && townDoc.byLaw}
                        target='_blank'
                    >
                        <IonIcon
                            slot='start'
                            ios={informationCircleOutline}
                            md={informationCircleSharp}
                        />
                        <IonLabel>Bylaw</IonLabel>
                    </IonItem>
                    <IonItemDivider />
                    <IonMenuToggle autoHide={false}>
                        <IonItem button onClick={logout} lines='none' detail={false}>
                            <IonIcon slot='start' ios={logOutOutline} md={logOutSharp} />
                            <IonLabel>Log out</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
