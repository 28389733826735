import React, { useState, useEffect } from 'react';
import { IonLoading, IonButton } from '@ionic/react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useElements, useStripe, Elements } from '@stripe/react-stripe-js';
import './CheckoutForm.css';
/* Pullstate Store */
import { UserStore } from '../../store/UserStore';
import { PermitStore } from '../../store/PermitStore';
import { TownStore } from '../../store/TownStore';
import firebaseApp, { firestore } from '../../firebase';
import { useHistory } from 'react-router';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
    hidePostalCode: false,
};

const Form = () => {
    const history = useHistory();
    const application = PermitStore.useState((s) => s.application);
    // const applicationStep = PermitStore.useState(s => s.applicationStep);
    const userDoc = UserStore.useState((s) => s.userDoc);
    const user = UserStore.useState((s) => s.user);
    const townDoc = TownStore.useState((s) => s.townDoc);
    const elements = useElements();
    const stripe = useStripe();
    const [errorMessage, setErrorMessage] = useState(null);
    const [paymentIntent, setPaymentIntent] = useState(null);
    const [paymentId, setPaymentId] = useState(null);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        const api = townDoc.functionsEndpoint + 'payments';

        async function createIntent() {
            // console.log('triggered');
            const url = api + '/intents';
            const params = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: user.email,
                    price: application.price * 100,
                    user: userDoc.id,
                    currency: townDoc.currency,
                }),
            };

            return await fetch(url, params)
                .then((response) => response.json())
                .then((data) => {
                    // console.log(data)
                    setPaymentIntent(data);
                });
        }
        if (application.price) {
            createIntent();
        }
    }, [application.price, townDoc.currency, townDoc.functionsEndpoint, user.email, userDoc.id]);

    // useEffect(() => {
    //   console.log(paymentIntent);
    // }, [paymentIntent]);

    useEffect(() => {
        const createPermit = () => {
            setShowLoading(true);
            firestore
                .collection('permits')
                .doc(userDoc.lastName + '-' + application.type + '-' + application.startDate)
                .set({
                    ...application,
                })
                .then(() => {
                    firestore
                        .collection('users')
                        .doc(firebaseApp.auth().currentUser.uid)
                        .collection('permits')
                        .doc(
                            userDoc.lastName + '-' + application.type + '-' + application.startDate
                        )
                        .set({
                            ...application,
                        });
                })
                .then(() => {
                    setShowLoading(false);
                    PermitStore.update((s) => {
                        s.applicationStep = 7;
                    });
                })
                .catch((error) => {
                    console.log(error);
                    setShowLoading(false);
                });
        };
        if (paymentId) {
            createPermit();
            setShowLoading(false);
        } else return;
    }, [
        application,
        application.address,
        application.otherAddress,
        application.price,
        application.startDate,
        application.type,
        history,
        paymentId,
        user.lastName,
        userDoc.lastName,
    ]);

    const handleChange = (event) => {
        if (event.error) {
            setErrorMessage(event.error.message);
        } else {
            setErrorMessage(null);
        }
    };

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setShowLoading(true);
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const card = elements.getElement(CardElement);
        const result = await stripe.confirmCardPayment(paymentIntent.client_secret, {
            payment_method: {
                card: card,
                billing_details: {
                    name: userDoc.firstName + ' ' + userDoc.lastName,
                },
            },
        });

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
            setErrorMessage(result.error.message);
            setShowLoading(false);
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.
                // console.log(result);
                setPaymentId(result.paymentIntent.id);
                // createPermit();
            }
        }
    };

    return (
        <div>
            <IonLoading
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
            />
            <form onSubmit={handleSubmit}>
                <div className='form-row' style={{ margin: '0 auto', textAlign: 'center' }}>
                    <label htmlFor='card-element'>Credit or debit card</label>
                    <div style={{ margin: '0 auto', textAlign: 'center' }}>
                        <CardElement
                            id='card-element'
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='card-errors' role='alert'>
                        {errorMessage}
                    </div>
                </div>
                <IonButton shape='round' expand='block' color='success' type='submit'>
                    Submit Payment
                </IonButton>
            </form>
        </div>
    );
};

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
        },
    ],
};
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_TcWOWQAbtdfIMZTc3iNMm6xK');

const CheckoutForm = () => {
    return (
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <Form />
        </Elements>
    );
};

export default CheckoutForm;
