import firebase from "firebase/app";
import 'firebase/auth';        // for authentication
import 'firebase/firestore';   // for cloud firestore
import 'firebase/functions';   // for cloud functions
import 'firebase/storage';   // for cloud storage
import { toast } from './components/Toast/Toast';

const firebaseConfig = {
  apiKey: "AIzaSyDHMgHxdq5hKkN817fcDALQCEjLQYqwM00",
  authDomain: "lincolnfirepermitsonline.firebaseapp.com",
  databaseURL: "https://lincolnfirepermitsonline.firebaseio.com",
  projectId: "lincolnfirepermitsonline",
  storageBucket: "lincolnfirepermitsonline.appspot.com",
  messagingSenderId: "30401353218",
  appId: "1:30401353218:web:8cecb9d2a208cb0ca2391b",
  measurementId: "G-5CX77TWRED"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.functions();
firebase.firestore();
firebase.storage();
firebase.firestore().settings({
  ignoreUndefinedProperties: true,
})
export default firebaseApp;
export const auth = firebase.auth();
export const storage = firebase.storage();
export const firestore = firebase.firestore();

export async function loginUser(email, password) {
  try {
    const res = await firebase.auth().signInWithEmailAndPassword(email, password)
    console.log(res)
    return true
  } catch(error) {
    toast(error.message, 4000)
    return false
  }
}
export function logoutUser() {
  return firebase.auth().signOut()
}

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  firebase.auth().signInWithPopup(googleAuthProvider)
  .then(() => {
  })
  .catch((error) => {
    alert(error.message)
  });;
};

var msProvider = new firebase.auth.OAuthProvider('microsoft.com');

msProvider.setCustomParameters({
  // Force re-consent.
  prompt: 'consent',
  // Target specific email with login hint.
  login_hint: 'user@outlook.com'
});
export const signInWithMS = () => {
  firebase.auth().signInWithPopup(msProvider)
    .then(() => {
    })
    .catch((error) => {
      alert(error.message)
    });
}

var fbProvider = new firebase.auth.FacebookAuthProvider();
// fbProvider.setCustomParameters({
//   // 'display': 'popup'
// });

export const signInWithFB = () => {
firebase
  .auth()
  .signInWithPopup(fbProvider)
  .then(() => {
  })
  .catch((error) => {
    var errorMessage = error.message;

    alert(errorMessage);
  });
}